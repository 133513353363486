<template>
  <div class="bodycont">

    <comHeader />
    <div class="infobanner"></div>
    <div class="main">
      <div class="news_list">
           <infonavtab  :cindex='cindex' />
      </div>
       <div class="infocont infocont4">
         <p>联系人：{{dataInfo.asPerson}}</p>
         <p>电话：{{dataInfo.asTel}}</p>
         <p>邮箱：{{dataInfo.asEmail}}</p>
         <p>地址：{{dataInfo.asAddress}}</p>
          <iframe id="topFrame" src="https://j.map.baidu.com/de/CkFi" name="topFrame" width="1000px" height="600px" frameborder="0" scrolling="No"></iframe>
       </div>
      
      
   </div>

  <bNav />

  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import navTitle from '@/components/navtitle.vue'
  import infonavtab from './components/navtababout.vue'
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {
    components: {
      infonavtab,
      comHeader,
      bNav,
      navTitle,
    },
    data() {
      return {
        dataInfo:{},
        cindex:4,
      }
    },
    mounted() {
         this.getInfo()
    },
    methods: {
      //获取列表
      getInfo() {
        let data = {
          asId: 1,
        }
        postRequest(api.aboutUsId, data).then(res => {
           this.dataInfo = res.data
        })

      },

    }
  }
</script>

<style lang="less" scoped>
  .news_list {
    width: 200px;
    overflow: hidden;
    float: left;
  }

  .bodycont {
    margin: 0px;
    background-size: 100% 100%;
    background-attachment: fixed;
  }

  .main {
    width:1190px;
    margin: 20px auto;
    min-height:73vh;
    display: flex;
    justify-content: space-between;
  }

  .infocont {

    width: 80%;
  padding: 20px;
   // box-shadow: 0px 5px 14px 0 #ddd;

  }

  .infodiv {
    width: 910px;
  }

  .infocont4{
    p{
      margin-bottom: 20px;
    }
  }
   .infobanner{
   background: url('../../assets/images/banner/about.png') center center no-repeat;
   width: 100%;
   height:350px;
   background-size:cover;
 }
</style>
